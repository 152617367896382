import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { Container as ContainerBase } from 'components/misc/Layouts.js'
import logo from '../../images/logo.svg'
import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg'
import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg'
import { ReactComponent as YoutubeIcon } from '../../images/youtube-icon.svg'
import { ReactComponent as PhoneIcon } from '../../images/phone.svg'
import { ReactComponent as EmailIcon } from '../../images/mail.svg'
import { ReactComponent as CallIcon } from '../../images/call.svg'
import { ReactComponent as InstagramIcon } from '../../images/instagram.svg'
import { ReactComponent as LinkedinIcon } from '../../images/linkedin.svg'
import { ReactComponent as CalenderIcon } from '../../images/calendar.svg'
import { components } from 'ComponentRenderer.js'

const Container = tw(
  ContainerBase
)`text-gray-100  -mb-8 bg-darkWhite text-black`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center text-gray-800 justify-around flex-row px-8 mt-2 mr-2 mt-2`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const LogoImg = tw.img`w-96`
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`
const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row `
const Link = tw.a`border-b-2 border-transparent hocus:text-red-800 hocus:border-red-800 pb-1 transition duration-300 mt-2 mx-4 `

const SocialLinksContainer = tw.div`border-black`

const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 mt-2 hover:text-gray-500 transition duration-300 mx-4 `}
  svg {
    ${tw`w-4 h-4 hocus:text-red-800 hocus:border-red-800`}
  }
`

const ContactIcon = styled.a`
  ${tw`cursor-pointer  text-gray-800 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-800 mr-2`
const IconContainer = tw.p`text-center mt-4  `
const ContactText = tw.p`flex text-center text-gray-800 mt-2 font-medium tracking-wide text-sm ml-1`

export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={'https://i.ibb.co/KKT5msS/redkeys-light-1.png'} />
            {/* <LogoText>Red Keys</LogoText> */}
          </LogoContainer>
        </Row>

        <Row>
          <LinksContainer>
            <ContactText style={{ marginRight: '1.5rem' }}>
              {' '}
              <CallIcon
                style={{ marginRight: '0.5rem', marginLeft: '1rem' }}
              />{' '}
              0706 339 8622
            </ContactText>
            <ContactText style={{ marginRight: '1.5rem' }}>
              <EmailIcon
                style={{ marginRight: '0.5rem', marginLeft: '1rem' }}
              />{' '}
              info@rkgproperties.com{' '}
            </ContactText>
          </LinksContainer>
        </Row>
        <IconContainer>
          {/* <SocialLink href="https://facebook.com">
            <FacebookIcon color="#333333" />
          </SocialLink> */}
          {/* <SocialLink href="https://twitter.com">
            <TwitterIcon color="#333333" />
          </SocialLink> */}

          {/* <SocialLink href='https://youtube.com'>
            
            <InstagramIcon color='#333333' />
          </SocialLink> */}
          {/* <SocialLink href="https://youtube.com">
            <LinkedinIcon color="#333333" />
          </SocialLink> */}
          {/* <SocialLink href="https://youtube.com">
            <CalenderIcon color="#333333" />
          </SocialLink> */}
        </IconContainer>

        <Row>
          <LinksContainer>
            <Link href='/'>Home</Link>
            <Link href='/properties'>Properties</Link>
            <Link href='/services'>Services</Link>
            <Link href='/meet-the-partner'>Meet the Partners</Link>
            <Link href='/contact'>Contact</Link>
            {/* <Link href={components.landingPages.ServiceLandingPage.url}>
              New Developments
            </Link> */}
            {/* <Link href='#'>Blog</Link>
            <Link href='#'>Reviews</Link> */}
          </LinksContainer>
        </Row>
        <CopyrightText>
          &copy; Copyright 2020, Red Keys. All Rights Reserved.
        </CopyrightText>
      </Content>
    </Container>
  )
}
