import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { components } from 'ComponentRenderer.js';

import Header, {
  LogoLink,
  NavLinks,
  NavLink as NavLinkBase,
} from '../headers/light.js';

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6 
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-144 min-h-144 opacity-50`}
  background-image: url("https://i.ibb.co/stqSzGc/Rectangle-37.png");
`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex-col`;

const SubHeading = styled.p`
  ${tw`text-sm text-center font-medium mt-8 max-w-sm text-white`}
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;
const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-baseRed text-black hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  background-image: url('https://images.unsplash.com/photo-1551918120-9739cb430c6d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&width=1440&height=1024&q=75');
  ${tw`bg-green-500 bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.div`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`lg:block sm:block rounded bg-center justify-center h-screen sm:h-80 w-80 lg:h-128 lg:w-128`,
]);

export default ({
  home = components.home,
  innerPages = components.innerPages,
  Consultation = components.Consultation,
  imageSrc = 'https://i.ibb.co/KKT5msS/redkeys-light-1.png',
  backgroundImage = 'https://images.unsplash.com/photo-1517074009205-d9ca5d8b4a63?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1867&q=80',
  navLinks = [
    <NavLinks key={1}>
      <NavLink href={components.home.Consultation.url}>
        Book a Consultation
      </NavLink>
      <NavLink href={components.home.MeetPartners.url}>
        Meet the Partners
      </NavLink>
      {/* <NavLink href={components.landingPages.ServiceLandingPage.url}>
        New Developments
      </NavLink> */}
      {/* <NavLink href='#'>Contact</NavLink> */}
    </NavLinks>,
  ],
  heading = (
    <>
      Find Perfect Hotels
      <wbr />
      <br />
      <span tw='text-primary-500'>anywhere you go.</span>
    </>
  ),
  description = "We've been in the hotels business across the world for 5 years now. We assure you that you will always enjoy your stay with us.",
  primaryActionUrl = '#',
  primaryActionText = 'Sign Up',
  secondaryActionUrl = '#',
  secondaryActionText = 'Search Hotels',
}) => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Image imageSrc={imageSrc} imageContain={true} imageShadow={false} />
        </Content>
      </HeroContainer>
    </Container>
  );
};
