import React, { useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import tw from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from '../misc/Headings.js'
import { PrimaryButton } from '../misc/Buttons.js'
import { ReactComponent as QuotesLeftIcon } from '../../images/quotes-l.svg'
import { ReactComponent as QuotesRightIcon } from '../../images/quotes-r.svg'
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg'
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-4.svg'
import { ReactComponent as SvgDecoratorBlob2 } from '../../images/svg-decorator-blob-5.svg'
import { ReactComponent as LocationIcon } from 'feather-icons/dist/icons/map-pin.svg'

import 'slick-carousel/slick/slick.css'

const Container = tw.div`relative`
const Content = tw.div` mx-auto py-20 lg:py-24`
const TestimonialsContainer = tw.div`mb--16`
const Testimonials = styled.div``
const Testimonial = tw.div`lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`

const TestimonialImageSlider = tw(Slider)`w-full  flex-shrink-0 `
const TestimonialTextSlider = tw(Slider)``
const TestimonialText = tw.div`outline-none`

const ImageAndControlContainer = tw.div`relative outline-none p-10 lg:p-24 md:p-16`
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded  bg-center h-80 sm:h-96 lg:h-144`,
])

const ControlContainer = tw.div`absolute bottom-0 right-0 bg-gray-100 px-6 py-4 rounded-tl-3xl border`
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full text-gray-100 bg-gray-800 hocus:bg-gray-700 p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`

const TextContainer = styled.div((props) => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
])

const Subheading = tw(SubheadingBase)`mb-4`
const HeadingTitle = tw(
  SectionHeading
)`lg:text-left leading-tight text-gray-lightest font-cinzel`
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100 `

const QuoteContainer = tw.div`relative mt-10 lg:mt-10 `
const Quote = tw.blockquote`text-center lg:text-left text-sm sm:text-lg lg:text-xl xl:text-2xl text-gray-800`
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`
const CustomerProfilePicture = tw.img`rounded-full w-20 h-20`
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-1 mt-2 sm:mt-0 `
const PropertyName = tw.h3`font-semibold text-xl lg:text-2xl xl:text-3xl text-black mt-10 `
const PropertyPrice = tw.p`font-medium  mt-5 xl:text-2xl text-gray-800`
const Location = tw.blockquote`text-center mt-10 lg:text-left text-sm sm:text-lg lg:text-xl xl:text-2xl text-black `

const QuotesLeft = tw(
  QuotesLeftIcon
)`w-6 h-6 opacity-75 text-primary-800 inline-block mr-1 -mt-3 `
const QuotesRight = tw(
  QuotesRightIcon
)`w-6 h-6 opacity-75 text-primary-500 inline-block ml-1 -mt-3 `
const LocationIconRight = tw(
  LocationIcon
)`w-6 h-6 opacity-75 text-gray-800 mr-2 inline-block ml-1 -mt-3 `

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full `
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8 `

export default ({
  subheading = '',
  heading = 'Featured Properties',
  description = '',
  testimonials = null,
  textOnLeft = false,
}) => {
  /*
   * You can modify the testimonials shown by modifying the array below or passing in the testimonials prop above
   * You can add or remove objects from the array as you need.
   */
  const defaultTestimonials = [
    {
      imageSrc:
        'https://images.unsplash.com/photo-1531300365552-da5abe58a725?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8aG91c2UlMjBpbiUyMGxhZ29zfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
      profileImageSrc:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
      quote:
        'High end building with 4 bedroom and a balcony and parking space yada',
      propertyName: 'Luxurous 4 bedroom apartment',
      location: 'Plot 144 Johnny Depp, Oniru',
      propertyPrice: '₦300,000,000',
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1541987817603-d2b3e0fad6d0?ixid=MXwxMjA3fDB8MHxzZWFyY2h8OHx8bGFnb3MlMjBob3VzZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
      profileImageSrc:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
      quote:
        'High end building with 4 bedroom and a balcony and parking space yada',
      propertyName: 'Luxurous 8 bedroom apartment',
      location: 'Plot 144 Johnny Depp, Oniru',
      propertyPrice: '₦150,000,000',
    },
  ]

  if (!testimonials || testimonials.length === 0)
    testimonials = defaultTestimonials

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null)
  const [textSliderRef, setTextSliderRef] = useState(null)

  return (
    <Container>
      <Content>
        {/* <HeadingInfo
          tw='text-center lg:hidden'
          subheading={subheading}
          heading={heading}
          description={description}
        /> */}
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider
                arrows={false}
                ref={setImageSliderRef}
                asNavFor={textSliderRef}
                fade={true}
              >
                {testimonials.map((testimonial, index) => (
                  <ImageAndControlContainer key={index}>
                    <Image imageSrc={testimonial.imageSrc} />
                    <ControlContainer>
                      <ControlButton onClick={imageSliderRef?.slickPrev}>
                        <ChevronLeftIcon />
                      </ControlButton>
                      <ControlButton onClick={imageSliderRef?.slickNext}>
                        <ChevronRightIcon />
                      </ControlButton>
                    </ControlContainer>
                  </ImageAndControlContainer>
                ))}
              </TestimonialImageSlider>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
      {/* <DecoratorBlob1 /> */}
      {/* <DecoratorBlob2 /> */}
    </Container>
  )
}

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? <Subheading>{subheading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
)
