import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right-icon.svg';
import SupportIconImage from 'images/support-icon.svg';
import ShieldIconImage from 'images/shield-icon.svg';
import CustomizeIconImage from 'images/customize-icon.svg';
import { ReactComponent as SvgDecoratorBlob3 } from 'images/svg-decorator-blob-3.svg';
import Footer from 'components/footers/MiniCenteredFooter.js';
import AnimationRevealPage from '../../helpers/AnimationRevealPage';
import Header from '../headers/light.js';
import navLinks from '../hero/TwoColumnWithVideo';
import { MenuHeader } from '../hero/TwoColumnWithVideo';


const Title = styled.h1`
  ${tw`text-center font-cinzel my-12 w-full font-bold md:text-4xl text-3xl lg:text-6xl`}
`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`my-20 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-between w-10/12 mx-auto`}
`;

const AboutUsContainer = styled.div`
  ${tw`mt-8 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;

const Brand = styled.div`
  ${tw`lg:text-3xl md:text-xl text-lg font-bold lg:mt-32 mt-24 font-cinzel w-11/12 lg:w-8/12 md:w-10/12 mx-auto text-black mb-2`};
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
  .title {
    ${tw`mt-4 font-bold text-3xl font-cinzel`}
  }
`;

const Card = styled.div`
  ${tw`flex flex-col items-start
   text-left h-full  rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105`}

  .description {
    ${tw`mt-4 text-sm text-secondary-300 font-inter text-base text-gray-700 flex justify-center`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

// const Card2 = styled.a`
//   ${tw`flex flex-col items-center text-center
//   h-full mx-6 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
//   .imageContainer {
//     ${tw`text-center rounded-full p-4 bg-gray-100`}
//     img {
//       ${tw`w-8 h-8`}
//     }
//   }

//   .title {
//     ${tw`mt-4 font-bold text-4xl font-cinzel`}
//   }

//   .description {
//     ${tw`mt-4 text-sm font-sans text-secondary-300 text-lightGrey `}
//   }
// `

const Text = styled.article`
  div {
    ${tw`bg-baseRed p-6 lg:p-10 md:p-10 my-8 mt-3 shadow-aboutUsCard relative m-auto w-11/12 lg:w-8/12 md:w-10/12 `}
  }
  div::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 40px;
    height: 100%;
    width: 100%;
    border: 4px solid #c41421;
    z-index: -1;
  }
  @media screen and (max-width: 480px) {
    div::before {
      position: absolute;
      top: 13px;
      left: 13px;
    }
  }
  @media screen and (width: 540px) {
    div::before {
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }
  p {
    ${tw`font-inter font-medium text-gray-800 lg:leading-10 leading-7 text-lg text-white`}
  }

  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({
  aboutUs = [
    {
      title: 'brand story',
      description: ``,
    },
  ],
  cards = [
    {
      // imageSrc: ShieldIconImage,
      title: 'misson',
      description:
        'At Red Keys Global Properties, our mission is to provide the most concise, highest standard of Real Estate Property Market Services to our Clients. To bring a value-add of Professional Team of Agents that provide the best personalized service ensuring a positive and memorable experience from beginning to end. Our number one Priority at RKG are our Clients.',
      url: 'https://timerse.com',
    },
    {
      // imageSrc: SupportIconImage,
      title: 'vision',
      description:
        'Our vision is to grow steadily into one of the Premier Real Estate Brokerages in Nigeria by using innovation and technology to advance us into the World class frontier of International brokerages and transactions. We want to ensure that our “live well” motto drives us to excel at providing world class services from our HNI clients down to local community projects.',
      url: 'https://google.com',
    },
    {
      // imageSrc: CustomizeIconImage,
      title: 'value',
      description:
        'Our Value system is the core of who we are At RKG Properties, and our ethos is to create a Fun, Inclusive and Diverse work environment where all members of our Team work together to keep our Mission and Vision alive.',
      url: 'https://reddit.com',
    },
  ],
  linkText = 'Learn More',
  heading = '',
  subheading = '',
  description = '',
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <MenuHeader />
      <ContentWithPaddingXl>
        <Title>ABOUT US</Title>
        <Brand>{aboutUs[0].title}</Brand>
        <Text>
          <div>
            <p>
              RKG Properties is a new real estate brokerage with an old soul. It
              is the union of 2 separate real estate brokerage owners (Tseshe
              Doyle-Egunjobi of AAE Global Properties and Ike Onyekweli of
              Caerus Strategic Development Ltd.), banning together to form a
              holy alliance.
              <br />
              <br />
              After years of working together on various real estate projects, a
              work admiration grew between them and the idea floated for a few
              years to partner up. With the pandemic hitting every sector, It
              became clear as day that the time had come to make it official.
              <br />
              <br />
              As a duo they could put both their vast knowledge and portfolio in
              the property market into action, and easily become a force to be
              reckoned with in the Nigerian Real Estate Sphere. The rest as they
              say is His & Her story.
              <br />
              <br />
              Sharing a common value system for Hardwork, Professionalism,
              Quality, Integrity it was of the utmost Important that RedKeys
              signified a Modern, Creative, Innovative workplace culture that
              would carry on their spirit of the Partners. We believe that
              working hard has to be rewarded with playing hard and at RKG we
              plan on making sure that our work life balance yields a productive
              environment where Team work and Fun are imbedded into our very
              fabric.
            </p>
          </div>
        </Text>

        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <p className='title'>{card.title}</p>
              <Card>
                <p className='description'>{card.description}</p>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <Footer />
    </Container>
  );
};
