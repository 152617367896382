import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js'
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings'
import { SectionDescription } from 'components/misc/Typography'
import { ReactComponent as TwitterIcon } from 'images/twitter-icon.svg'
import { ReactComponent as LinkedinIcon } from 'images/linkedin-icon.svg'
import { ReactComponent as GithubIcon } from 'images/github-icon.svg'
import WadeWarren from '../../images/meet-the-partners/founders/wade-warren.png'
import EstherHoward from '../../images/meet-the-partners/founders/esther-howard.png'

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)`text-left text-black font-cinzel`
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto `
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center mr-16 ml-auto`
const CardImage = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`w-64 h-64 bg-cover bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6 `}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-gray-800 `}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-black font-cinzel`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
  heading = 'Meet The Partners',
  subheading = null,
  description = null,
  cards = [
    {
      imageSrc: WadeWarren,
      position: 'Co-Founder',
      name: 'Wade Warren',
      // links: [
      //   {
      //     url: "https://twitter.com",
      //     icon: TwitterIcon,
      //   },
      //   {
      //     url: "https://linkedin.com",
      //     icon: LinkedinIcon,
      //   },
      //   {
      //     url: "https://github.com",
      //     icon: GithubIcon,
      //   },
      // ],
    },
    {
      imageSrc: EstherHoward,
      position: 'Co-Founder',
      name: 'Esther Howard',
      // links: [
      //   {
      //     url: "https://twitter.com",
      //     icon: TwitterIcon,
      //   },
      //   {
      //     url: "https://linkedin.com",
      //     icon: LinkedinIcon,
      //   },
      //   {
      //     url: "https://github.com",
      //     icon: GithubIcon,
      //   },
      // ],
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading ? <Subheading>{subheading}</Subheading> : <></>}
          {heading ? <Heading>{heading}</Heading> : <></>}
          {description ? <Description>{description}</Description> : <></>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                {card && card.name ? (
                  <span className="name">{card.name}</span>
                ) : (
                  <></>
                )}
                {card && card.position ? (
                  <span className="position">{card.position}</span>
                ) : (
                  <></>
                )}
                {card && card.links ? (
                  <CardLinks>
                    {card.links.map((link, linkIndex) => (
                      <a key={linkIndex} className="link" href={link.url}>
                        <link.icon className="icon" />
                      </a>
                    ))}
                  </CardLinks>
                ) : (
                  <></>
                )}
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  )
}
