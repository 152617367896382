import React from 'react'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings'
import { SectionDescription } from 'components/misc/Typography'
import { ReactComponent as SvgDotPatternIcon } from 'images/dot-pattern.svg'
// CHANGE TO AWS
import Image1 from 'images/grid/1.png'
import Image2 from 'images/grid/2.png'
import Image3 from 'images/grid/3.png'
import Image4 from 'images/grid/4.png'

const HeadingContainer = tw.div`text-center `
const Subheading = tw(SubheadingBase)`mb-4 `
const Heading = tw(SectionHeading)``
const Description = tw(SectionDescription)`mx-auto`

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`
// const Post = tw.a`flex flex-col h-full bg-gray-200 rounded`;
const Post = tw.a`flex flex-col rounded`
const PostImage = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-64 sm:h-128 bg-center bg-cover rounded-t `}
`
const PostText = tw.div`flex-1 px-6 py-8`
const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `
const PostDescription = tw.p``
const AuthorInfo = tw.div`flex`
const AuthorImage = tw.img`w-12 h-12 rounded-full mr-3`
const AuthorTextInfo = tw.div`text-xs text-gray-600`
const AuthorName = tw.div`font-semibold mt-2`
const AuthorProfile = tw.div`pt-1 font-medium`

const PostContainer = styled.div`
  ${tw`relative z-20 pt-3 pr-3 w-full sm:w-1/2 lg:w-1/2 max-w-sm mx-auto sm:max-w-none sm:mx-0 `}

  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full sm:w-full lg:w-2/3 `}
      ${Post} {
        ${tw`sm:flex-row items-center sm:pr-3 `}
      }
      ${PostImage} {
        ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l `}
      }
      ${PostTitle} {
        ${tw`text-2xl `}
      }
      ,
      ${PostText} {
        ${tw`pl-8 pr-5`}
      }

      ${PostDescription} {
        ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
      }
      ${AuthorInfo} {
        ${tw`mt-8 flex items-center`}
      }
      ${AuthorName} {
        ${tw`mt-0 font-bold text-gray-700 text-sm`}
      }
    `}
`

const DecoratorBlob1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`
const DecoratorBlob2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`

export default ({
  subheading = '',
  heading = 'We love writing.',
  description = '',
  posts = [
    {
      postImageSrc: 'https://i.ibb.co/GWNH0xp/rentals.png',
      // "https://images.unsplash.com/photo-1512699355324-f07e3106dae5?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjR8fGVzdGF0ZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
      // title: "Choosing the perfect Safaris in Africa",
      // authorName: "Sam Phipphen",
      url: '/rentals',
    },
    {
      postImageSrc: 'https://i.ibb.co/wgkkfsb/sales.png',
      // "https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NHx8Y291Y2h8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
      // title: "Hiking during the monsoon in Asia",
      // authorName: "Tony Hawk",
      url: '/sales',
    },
    {
      postImageSrc: 'https://i.ibb.co/K5swfpF/lifestyle.png',
      // "https://images.unsplash.com/photo-1481253127861-534498168948?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTN8fGJ1aWxkaW5nfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
      // title: "Must carry items while travelling to Thailand",
      // authorName: "Himali Turn",
      url: '/lifestyle',
    },
    {
      postImageSrc: 'https://i.ibb.co/jrk03bM/consult.png',
      // "https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8cGFydG5lcnNoaXB8ZW58MHx8MHw%3D&auto=format&fit=crop&w=800&q=60",
      // title: "Must carry items while travelling to Thailand",
      // authorName: "Himali Turn",
      url: '/consultation',
    },
  ],
}) => {
  return (
    <Container style={{ marginBottom: '4rem' }}>
      <ContentWithPaddingXl>
        {/* <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer> */}
        <Posts>
          {posts.map((post, index) => (
            <PostContainer featured={post.featured} key={index}>
              <Post className='group' href={post.url}>
                <PostImage imageSrc={post.postImageSrc} />
              </Post>
            </PostContainer>
          ))}
          {/* <DecoratorBlob1 />
          <DecoratorBlob2 /> */}
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  )
}
