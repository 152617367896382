import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'

import { css } from 'styled-components/macro' //eslint-disable-line
// import { ReactComponent as SvgDotPatternIcon } from '../../../images/dot-pattern.svg';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import Hero from 'components/hero/TwoColumnWithVideo.js'
import axios from 'axios'

import { ReactComponent as LocationIcon } from '../images/phone-icon2.svg'
import { ReactComponent as EmailIcon } from '../images/email-icon.svg'

import ReactPlayer from 'react-player'

import { ReactComponent as FacebookIcon } from '../images/facebook-icon.svg'
import { ReactComponent as TwitterIcon } from '../images/twitter-icon.svg'
import { ReactComponent as InstagramIcon } from '../images/instagram-icon.svg'
import { ReactComponent as LinkednIcon } from '../images/linkedin-icon.svg'
import { ReactComponent as CalendlyIcon } from '../images/linkedin-icon.svg'
import Grid from 'components/blogs/GridWithFeaturedPost.js'
import emailjs from 'emailjs-com'

import FeaturedPropertiesSlideshow1 from 'components/properties/FeaturedPropertiesSlideshow1'

import Footer from 'components/footers/MiniCenteredFooter.js'

import Pdf from '../brochure/hallmark.pdf'

// import { Button, Field, Form, RadioGroup, Select } from 'react-form-package'

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from '../components/headers/light'

const IconContainer = styled.div`
  ${tw`inline-grid rounded-full mr-1  `}
  svg {
    ${tw`w-6 h-3  `}
  }
`

const SocialLinksContainer = tw.div`ml-12 inline`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block   hover:text-red-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`

const Container = tw.div`relative mx-5`
const Content = tw.div` lg:w-4/6 sm:w-5/6  mx-auto py-20 lg:py-24  rounded-tl-2xl rounded-br-2xl `

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`
const ModalContainer = styled.div`
  ${tw`lg:p-4 mb-12 flex justify-center items-center`}
`
const Wrapper = styled.div`
  ${tw`border-4 border-r-0 flex justify-center items-center  lg:w-3/12 lg:h-6/12 md:h-24 md:w-24 w-16 h-12 border-baseRed py-10`}
  h1 {
    ${tw`font-cinzel  font-bold lg:text-3xl  text-sm md:text-base text-gray-800 lg:mb-2`}
    position: relative;
    top: 5px;
    left: 65px;
  }

  .line1 {
    ${tw``}
    border-right: 4px solid #c41421;
    height: 20px;
    position: absolute;
    top: 37px;
    right: 251px;
  }
  @media screen and (max-width: 900px) {
    .line1,
    .line2 {
      display: none;
    }
    h1 {
      top: 0;
      left: 35px;
    }
  }

  .line2 {
    ${tw``}
    border-right: 4px solid #c41421;
    height: 20px;
    position: absolute;
    top: 155px;
    right: 251px;
  }
  white-space: nowrap;
`
//Source Code for Form: https://tailwindcomponents.com/component/basic-contact-form

const HeaderTitle = styled.div`
  ${tw`font-cinzel font-bold text-gray-800 text-2xl md:text-3xl lg:text-5xl text-center my-6`}
`
const HeaderSubtitle = styled.div`
  ${tw`font-cinzel text-2xl   text-center my-5`}
`

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black hover:border-baseRed hover:text-black text-base font-light`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500 `}
  }
`

const Section = tw.div`text-gray-600 overflow-hidden `
const DivContainer = tw.div`container mx-auto`
const InnerDiv = tw.div`lg:m-12 `
const DoubleInnerDiv = tw.div`mx-4 lg:mx-0 font-cinzel   lg:p-3 lg:px-40   items-start md:block `
const DoubleInnerDivLeft = tw.div` mx-4 lg:mx-0 w-full   lg:w-3/6  font-cinzel p-12 flex flex-col lg:items-end `
const HeaderSpan = tw.span`font-bold pb-5 text-center`
const SubheaderSpan = tw.span`font-bold pb-5 text-center items-center justify-center mb-6 md:mb-8 lg:mb-8 m-auto`

const Span = tw.span`inline-block py-1 px-2 rounded bg-purple-500 text-white text-xs font-medium tracking-widest`
const BackgroundImage = tw.div` w-full hidden  md:flex flex-wrap justify-center mt-8 mb-4`
const YoutubeBackgroundImage = tw.div` hidden md:flex flex-wrap justify-center mt-8 mb-4 `
const BackgroundImageSm = tw.div` w-full  md:flex flex-wrap justify-center mt-8 mb-4 lg:hidden md:hidden`
const HeaderWrapper = tw.div`mt-4`
const StrattonContent = tw.div`flex justify-center items-center text-black lg:text-lg mt-10 leading-relaxed`
const Button = tw.button` flex mx-auto text-black font-medium  border-2 border-red-500 py-2 px-8 focus:outline-none hover:bg-red-600 hover:text-white rounded `
const Paragraph1 = tw.span`pb-5 text-center`
const Paragraph2 = tw.span`pb-5 block pt-10`

const ColumnDiv = tw.div` p-12 flex flex-wrap -mx-8 overflow-hidden sm:-mx-2 lg:-mx-3 xl:-mx-3 `
const ColumnContent = tw.div`my-8 px-8 w-1/2 overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 lg:my-3 lg:px-3 xl:my-3  xl:px-3 xl:w-1/2 bg-red-500`
const GridContainer = tw.div`p-12`

const RequestFormContainer = styled.div`
  ${tw`w-11/12 m-auto lg:px-4  flex justify-center items-center`}
  ${
    '' /* form {
    ${tw`w-full`}
  } */
  }
  .full-name {
    ${tw`lg:mb-4 md:mb-4 mb-2`}
  }
  .phone-number {
    ${tw`lg:mb-4 md:mb-4 mb-2`}
  }
  .email {
    ${tw`lg:mb-8 md:mb-8 mb-6`}
  }
  .text-field {
    ${tw`mb-4 `}
  }
  label {
    ${tw`text-gray-800`}
  }
  input,
  textarea {
    ${tw`border-2 border-gray-500 outline-none w-full lg:w-144 md:w-128 px-4 lg:px-8 lg:py-3 md:py-3 py-1`}
  }
  textarea {
    ${tw`h-32 md:h-56 lg:h-56 `}
  }
  h5 {
    ${tw`mb-8 font-inter font-bold leading-5 lg:text-lg md:text-lg text-base font-medium`}
  }
  p {
    ${tw`text-center text-white mb-4 bg-red-600`}
  }
  button {
    ${tw`border border-baseRed float-right px-8 py-2 font-bold text-base hover:bg-baseRed focus:bg-baseRed hover:text-white focus:text-white`}
    transition: 0.7s all;
  }
`
const Btn = styled.div`
  ${tw` border-2 border-red-600 text-sm md:text-base lg:text-lg w-48 md:w-56 lg:w-64 my-4 lg:px-2 font-bold tracking-wider py-2 mx-auto text-center text-gray-900 cursor-pointer transition duration-300 ease-in hover:bg-red-600 hover:text-white font-cinzel mt-12 lg:mt-2 mb-8 lg:mb-24 rounded`}
`

const Heading2 = tw.span`pt-3 block pb-3`

const Input = tw.input` w-full appearance-none block bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`

const RequestForm = () => {
  const [showMessage, isShowing] = useState(false)
  const messageShowing = () => isShowing(true)
  const messageNotShowing = () => isShowing(false)

  async function refreshPage() {
    setTimeout(() => {
      window.location.reload(false)
    }, 1000)
  }

  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_d3sqabj',
        'template_r4x0vgn',
        e.target,
        'user_3upRs0j9qJy7MUL32vi62'
      )
      .then(
        (result) => {
          console.log(result.text)
          console.log('Success!')
          messageShowing()

          refreshPage()
        },
        (error) => {
          console.log('Failure!')
          console.log(error.text)
          messageNotShowing()
        },
        e.target.reset
      )
  }

  return (
    <RequestFormContainer>
      <form onSubmit={sendEmail}>
        <h5>Interested to find out more about this property?</h5>
        <div className='full-name'>
          <label htmlFor='first-name'>Full name:</label>
          <br />
          <input type='text' placeholder='John Doe' name='from_name' />
        </div>
        <div className='phone-number'>
          <label htmlFor='phone-number'>Phone number:</label>
          <br />
          <input type='number' placeholder='070 5555 3334' name='from_number' />
        </div>
        <div className='email'>
          <label htmlFor='email'>Email:</label>
          <br />
          <input
            type='email'
            placeholder='example@gmail.com'
            name='from_email'
          />
        </div>
        <div className='text-field'>
          <textarea
            name=''
            id=''
            cols='10'
            rows='5'
            placeholder='Please send me more information on simi gardens'
            name='message'
          ></textarea>
        </div>

        {showMessage ? <p>Your request has been sent.</p> : <div></div>}

        <button type='submit' value='Send'>
          Submit
        </button>
      </form>
    </RequestFormContainer>
  )
}

const ExtraLinksContainer = tw.div`inline-block hover:border-white px-24 hidden lg:block  `

const PropertyTitle = tw.div`   flex h-32  font-cinzel font-bold text-xl md:text-6xl  items-center justify-center`
const Subheader = tw.div`   flex h-32  font-cinzel font-bold text-2xl  items-center justify-center`
const PropertyType = tw.div`   flex  font-cinzel text-lg   items-center justify-center mb-6 md:mb-8 lg:mb-8`

export default () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const navLinks = [
    <NavLinks key={1}>
      {/* <NavLink href="#about">
          About
        </NavLink> */}
      <ExtraLinksContainer>
        <NavLink href='/#'>
          <IconContainer>
            <LocationIcon />
          </IconContainer>
          0706 339 8622
        </NavLink>
        <NavLink href='/#'>
          <IconContainer>
            <EmailIcon />
          </IconContainer>
          info@RKGproperties.com
        </NavLink>

        <SocialLinksContainer>
          {/* <SocialLink href='https://facebook.com'>
            <FacebookIcon />
          </SocialLink> */}
          {/* <SocialLink href='https://twitter.com'>
            <TwitterIcon />
          </SocialLink> */}
          <SocialLink href='https://www.instagram.com/rkgproperties/'>
            <InstagramIcon />
          </SocialLink>
          {/* <SocialLink href='https://twitter.com'>
            <LinkednIcon />
          </SocialLink> */}
          {/* <SocialLink href='https://twitter.com'>
            <CalendlyIcon />
          </SocialLink> */}
        </SocialLinksContainer>
      </ExtraLinksContainer>
    </NavLinks>,
  ]
  return (
    <div>
      <Container>
        <HeaderWrapper>
          <StyledHeader links={navLinks} />
        </HeaderWrapper>

        <PropertyTitle> RKG Sales</PropertyTitle>

        {/* <SubheaderSpan>3 Loop Cabin</SubheaderSpan> */}
        <PropertyType></PropertyType>

        <Section>
          <DivContainer>
            <InnerDiv>
              <DoubleInnerDiv>
                <span class=' font-cinzel inline-block py-1 px-2 rounded bg-indigo-50 text-black text-xs font-bold tracking-widest'></span>

                <StrattonContent>
                  <p class='leading-relaxed mb-8'>
                    At RKG Properties we understand that Buying and Selling of
                    your home be it for personal or investment opportunities is
                    one of the biggest commitments you will make. This requires
                    expertise knowledge in various factors needed to make such a
                    big decision. Our team of agents will work in ensuring we
                    are matching our clients needs with counterparts able to
                    meet their respective goals and wants.
                  </p>
                </StrattonContent>
              </DoubleInnerDiv>

              <DoubleInnerDiv>
                <span class=' font-cinzel inline-block py-1 px-2 rounded bg-indigo-50 text-black text-xs font-bold tracking-widest'></span>
                <StrattonContent>
                  <p>
                    <b> Buying</b> - The buying process requires a lot of box
                    ticking. Whether this is an investment property or your
                    first home, you need real time market data to help make that
                    big decision to buy. RKG’s team of expert brokers will
                    provide you with comprehensive, top services that will help
                    make the buying process more enjoyable and less stressful.
                  </p>
                </StrattonContent>
                <StrattonContent>
                  <p>
                    <b> Selling</b> - Listing your home requires a great deal of
                    care. Understand our clients needs is paramount -
                    Sentimental, investment, business or whatever the reason we
                    are here to guide you through the process as best we can.
                  </p>
                </StrattonContent>

                <StrattonContent>
                  <p>
                    The Listing process highlights the importance of having
                    experienced real estate brokers on hand. This can make all
                    the difference in getting the best possible deal for your
                    property.
                    <br />
                    You need a company who is equipped to handle every aspect of
                    the sale process, from strategic marketing to carrying out
                    inspections and viewings to ensuring the right match is made
                    as per our clients needs. RKG properties guarantees we will
                    provide you with best in class, transparent, confidential
                    and professional services every step of the way.
                  </p>
                </StrattonContent>
              </DoubleInnerDiv>
            </InnerDiv>
          </DivContainer>
        </Section>
      </Container>

      <Footer />
    </div>
  )
}
