import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { css } from 'styled-components/macro' //eslint-disable-line
import { SectionHeading } from 'components/misc/Headings.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js'
import Calendly from 'components/meeting/calendly.js'

const Row = tw.div`flex flex-col lg:flex-row -mb-10`
const Heading = tw(
  SectionHeading
)`text-left text-black lg:text-4xl xl:text-5xl mb-16 font-cinzel`

const PopularPostsContainer = tw.div`lg:w-2/3`
const PostsContainer = tw.div`mt-12 flex flex-col sm:flex-row sm:justify-between lg:justify-start`
const CalendlyContainer = tw.div`mt-8 flex-col sm:w-full lg:w-144`
const Post = tw(
  motion.a
)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`
const Image = styled(motion.div)((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`,
])
const Title = tw.h5`mt-6 text-xl font-bold transition duration-300 group-hover:text-primary-500`
const Description = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`
const AuthorInfo = tw.div`mt-6 flex items-center`
const AuthorImage = tw.img`w-12 h-12 rounded-full`
const AuthorNameAndProfession = tw.div`ml-4`
const AuthorName = tw.h6`font-semibold text-lg`
const AuthorProfile = tw.p`text-secondary-100 text-sm`

const RecentPostsContainer = styled.div`
  ${tw`mt-24 lg:mt-0 lg:w-1/3`}
  ${PostsContainer} {
    ${tw`flex flex-wrap lg:flex-col`}
  }
  ${Post} {
    ${tw`flex justify-between mb-10 max-w-none w-full sm:w-1/2 lg:w-auto sm:odd:pr-12 lg:odd:pr-0 mr-0`}
  }
  ${Title} {
    ${tw`text-base xl:text-lg mt-0 mr-4 lg:max-w-xs`}
  }
  ${AuthorName} {
    ${tw`mt-3 text-sm text-secondary-100 font-normal leading-none`}
  }
  ${Image} {
    ${tw`h-56 w-72 flex-shrink-0`}
  }
`
const PostTextContainer = tw.div``

export default () => {
  // This setting is for animating the post background image on hover
  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: '100%',
    },
    hover: {
      backgroundSize: '110%',
    },
  }

  //Recommended: Only 2 Items

  const recentPosts = [
    {
      postImageSrc:
        'https://images.unsplash.com/photo-1601564892953-c7f49d311c28?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8cmVudGFsJTIwaG91c2V8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
      url: 'https://reddit.com',
    },
    {
      postImageSrc:
        'https://images.unsplash.com/photo-1596113199003-03babc2bdd2b?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mjl8fHJlbnRhbCUyMGhvdXNlfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    },
    {
      postImageSrc:
        'https://images.unsplash.com/photo-1602082550187-3f954840a0f7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjN8fHJlbnRhbCUyMGhvdXNlfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    },
  ]

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <PopularPostsContainer>
            <Heading>GET IN TOUCH WITH US TODAY</Heading>
            <CalendlyContainer>
              <Calendly />
            </CalendlyContainer>
          </PopularPostsContainer>

          <RecentPostsContainer>
            <PostsContainer>
              {recentPosts.map((post, index) => (
                <Post key={index} href={post.url} className="group">
                  <PostTextContainer>
                    <Title>{post.title}</Title>
                    <AuthorName>{post.authorName}</AuthorName>
                  </PostTextContainer>
                  <Image imageSrc={post.postImageSrc} />
                </Post>
              ))}
            </PostsContainer>
          </RecentPostsContainer>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  )
}
