import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import TeamImage from '../images/teamImage.png';
import { components } from 'ComponentRenderer.js';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';


const Image = styled.div`
  ${tw`bg-center flex justify-center items-center lg:mt-10`}
  img {
    ${tw`w-full object-cover h-team lg:h-teamLg`}
  }
`;

const Btn = styled.div`
  ${tw` border-2 border-red-600 text-sm md:text-base lg:text-lg w-48 md:w-56 lg:w-64 my-4 lg:px-2 font-bold tracking-wider py-2 mx-auto text-center text-gray-900 cursor-pointer transition duration-300 ease-in hover:bg-red-600 hover:text-white font-cinzel mt-12 mb-24`}
`;
const GlobalBtn = () => {
  return (
    <>
      <Link to='/meet-the-partner'>
        <Btn>MEET THE PARTNERS</Btn>
      </Link>
    </>
  );
};

const Title = styled.div`
  ${tw`font-cinzel text-black mb-4 font-bold lg:text-4xl md:text-3xl text-2xl`}
`;
const MeetTheTeam = () => {
  
  const ClickHandler = () => {
    ReactGA.event({
        category: 'Button',
        action: 'Click the button from first page'
      });
    alert('Send the information to google analystic that I touched the click button')
}

  return (
    <>
      <Title>MEET THE TEAM</Title>
      <Image>
        <img src={TeamImage} alt='meet the team' />
      </Image>
      <GlobalBtn onclick={ClickHandler} />
    </>
  );
};

export default MeetTheTeam;
