import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';

// import Header from "../headers/light.js";

// import ReactModalAdapter from '../../helpers/ReactModalAdapter.js';
// import ResponsiveVideoEmbed from '../../helpers/ResponsiveVideoEmbed.js';

// import { ReactComponent as PlayIcon } from 'feather-icons/dist/icons/play-circle.svg';
// import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
// import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-1.svg';
// import { ReactComponent as SvgDecoratorBlob2 } from '../../images/dot-pattern.svg';
import { ReactComponent as LocationIcon } from '../../images/phone-icon2.svg';
import { ReactComponent as EmailIcon } from '../../images/email-icon.svg';
import logo from '../../images/redkeys.gif';
// import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg';
// import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg';
import { ReactComponent as InstagramIcon } from '../../images/instagram-icon.svg';
// import { ReactComponent as LinkednIcon } from '../../images/linkedin-icon.svg';
// import { ReactComponent as CalendlyIcon } from '../../images/calendly-icon.svg';
import { Link } from 'react-router-dom';

import Header, {
  NavLink,
  NavLinks,
  // PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
  // MobileNavLinks,
} from '../headers/light.js';

export const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black hover:border-baseRed hover:text-black text-base font-light `}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500  `}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8  bg-center bg-cover h-screen min-h-96`}
  background-image: url("https://images.unsplash.com/photo-1528690041201-a8217b45b970?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80");
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-white opacity-75`;
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;

const Content = tw.div`lg:px-4 flex flex-1 flex-col justify-center items-center`;
const LinkBtn = tw.span`border-b-2 border-transparent  pb-1 transition duration-300 mt-1`;

// const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
// const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
// const RightColumn = tw.div`relative bg-center mt-12 lg:mt-0 flex flex-col justify-center `;

// const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
// const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

// const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
// const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
// const WatchVideoButton = styled.button`
//   ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
//   .playIcon {
//     ${tw`stroke-1 w-12 h-12`}
//   }
//   .playText {
//     ${tw`ml-2 font-medium`}
//   }
// `;

const SocialLinksContainer = tw.div`ml-12 inline `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block   hover:text-red-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const IconContainer = styled.div`
  ${tw`inline-grid rounded-full mr-1  `}
  svg {
    ${tw`w-6 h-3  `}
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative w-logo lg:max-w-3xl `;

// Random Decorator Blobs (shapes that you see in background)
// const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
//   ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
// `;
// const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
//   ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
// `;
const PrimaryAction = tw.button` px-8 py-3 text-sm sm:text-base  sm:px-8 sm:py-4 bg-transparent font-bold shadow transition duration-300 border border-baseRed text-black font-cinzel tracking-widest  focus:outline-none focus:shadow-outline hover:bg-red-600 hover:text-white`;

const ExtraLinksContainer = tw.div`inline-block hover:border-white px-24 md:hidden md:block `;

// const StyledModal = styled(ReactModalAdapter)`
//   &.mainHeroModal__overlay {
//     ${tw`fixed inset-0 z-50`}
//   }
//   &.mainHeroModal__content {
//     ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
//   }
//   .content {
//     ${tw`w-full lg:p-16`}
//   }
// `;
// const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

//       </Content>
//       <TwoColumn>
//           {/* <LeftColumn> */}
//             {/* <Heading>{heading}</Heading>
//             <Paragraph>{description}</Paragraph> */}
//             {/* <Actions>
//               <PrimaryButton as="a" href={primaryButtonUrl}>{primaryButtonText}</PrimaryButton>
//               <WatchVideoButton onClick={toggleModal}>
//                 <span className="playIconContainer">
//                   <PlayIcon className="playIcon" />
//                 </span>
//                 <span className="playText">{watchVideoButtonText}</span>
//               </WatchVideoButton>
//             </Actions> */}
//           {/* </LeftColumn> */}
//           <RightColumn>
//             {/* <IllustrationContainer>
//               <img
//                 css={imageCss}
//                 src={imageSrc}
//                 alt="Hero"
//               />
//               {imageDecoratorBlob && <DecoratorBlob2 />}
//             </IllustrationContainer> */}
//           </RightColumn>
//         </TwoColumn>
//         <DecoratorBlob1 />
//         <StyledModal
//           closeTimeoutMS={300}
//           className="mainHeroModal"
//           isOpen={modalIsOpen}
//           onRequestClose={toggleModal}
//           shouldCloseOnOverlayClick={true}
//         >
//           <CloseModalButton onClick={toggleModal}>
//             <CloseIcon tw="w-6 h-6" />
//           </CloseModalButton>
//           <div className="content">
//             <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
//           </div>
//         </StyledModal>
//       </HeroContainer>
//         <TwoColumn>
//           {/* <LeftColumn> */}
//             {/* <Heading>{heading}</Heading>
//             <Paragraph>{description}</Paragraph> */}
//             {/* <Actions>
//               <PrimaryButton as="a" href={primaryButtonUrl}>{primaryButtonText}</PrimaryButton>
//               <WatchVideoButton onClick={toggleModal}>
//                 <span className="playIconContainer">
//                   <PlayIcon className="playIcon" />
//                 </span>
//                 <span className="playText">{watchVideoButtonText}</span>
//               </WatchVideoButton>
//             </Actions> */}
//           {/* </LeftColumn> */}
//           <RightColumn>
//             {/* <IllustrationContainer>
//               <img
//                 css={imageCss}
//                 src={imageSrc}
//                 alt="Hero"
//               />
//               {imageDecoratorBlob && <DecoratorBlob2 />}
//             </IllustrationContainer> */}
//           </RightColumn>
//         </TwoColumn>
//         <DecoratorBlob1 />
//         <StyledModal
//           closeTimeoutMS={300}
//           className="mainHeroModal"
//           isOpen={modalIsOpen}
//           onRequestClose={toggleModal}
//           shouldCloseOnOverlayClick={true}
//         >
//           <CloseModalButton onClick={toggleModal}>
//             <CloseIcon tw="w-6 h-6" />
//           </CloseModalButton>
//           <div className="content">
//             <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
//           </div>
//         </StyledModal>
//       </Container>
//     </>
//   );
// };
export const MenuHeader = () => {
  const navLinks = [
    <ExtraLinksContainer key={1}>
      <NavLinks className=' navLinksContainer ' >
        {/* <NavLink href="#about">
      About
    </NavLink> */}

        <NavLink href='/#'>
          <IconContainer>
            <LocationIcon />
          </IconContainer>
          0706 339 8622
        </NavLink>
        <NavLink href='/#'>
          <IconContainer>
            <EmailIcon />
          </IconContainer>
          info@RKGproperties.com
        </NavLink>
        <SocialLinksContainer>
          {/* <SocialLink href='https://facebook.com'>
            <FacebookIcon />
          </SocialLink>
          <SocialLink href='https://twitter.com'>
            <TwitterIcon />
          </SocialLink> */}
          <SocialLink
            href='https://instagram.com/rkgproperties?utm_medium=copy_link'
            target='_blank'
          >
            <InstagramIcon />
          </SocialLink>
          {/* <SocialLink href='https://twitter.com'>
            <LinkednIcon />
          </SocialLink>
          <SocialLink href='https://twitter.com'>
            <CalendlyIcon />
          </SocialLink> */}
        </SocialLinksContainer>
      </NavLinks>
    </ExtraLinksContainer>,
  ];
  return <StyledHeader links={navLinks}  />;
};
// style={{maxWidth: "100vh"}}
export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <MenuHeader />
        <Content>
          {/* <TwoColumn> */}
          {/* <RightColumn> */}
          <IllustrationContainer>
            <img src={logo} alt='loading...' />
          </IllustrationContainer>
          {/* </RightColumn> */}
          {/* </TwoColumn> */}
          <Link to='/about'>
            <LinkBtn>
              <PrimaryAction>ABOUT US</PrimaryAction>
            </LinkBtn>
          </Link>
        </Content>
      </HeroContainer>
    </Container>
  );
};
