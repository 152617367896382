import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ReactComponent as LocationIcon } from '../images/phone-icon2.svg';
import { ReactComponent as EmailIcon } from '../images/email-icon.svg';
import logo from '../images/rk-intro.gif';
import { ReactComponent as FacebookIcon } from '../images/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from '../images/twitter-icon.svg';
import { ReactComponent as InstagramIcon } from '../images/instagram-icon.svg';
import { ReactComponent as LinkednIcon } from '../images/linkedin-icon.svg';
import { ReactComponent as CalendlyIcon } from '../images/calendly-icon.svg';
import Ike from '../images/Ike Onyekweli.jpg';
import Doyle from '../images/Tsehe Doyle-Egunjobi.jpeg';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import Footer from 'components/footers/MiniCenteredFooter.js';
import Services from 'components/cards/ServiceCards.js';
import ServicesTwo from 'components/cards/ServiceCards-Two.js';
import ServicesThree from 'components/cards/ServiceCards-Three.js';
import ServicesFour from 'components/cards/ServiceCards-Four.js';

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from '../components/headers/light.js';



const SocialLinksContainer = tw.div`ml-12 inline`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block   hover:text-red-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const IconContainer = styled.div`
  ${tw`inline-grid rounded-full mr-1  `}
  svg {
    ${tw`w-6 h-3  `}
  }
`;
const Title = styled.div`
  ${tw`font-cinzel font-bold text-gray-800 text-2xl md:text-3xl lg:text-5xl text-center my-12 lg:mt-12`}
`;

const h1 = styled.div`
  ${tw`text-xl`}
`;

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black hover:border-baseRed hover:text-black text-base font-light`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500 `}
  }
`;
const Wrapper = styled.div`
  ${tw`border-4 border-r-0 flex items-center lg:w-56 lg:h-64 md:h-32 md:w-32 w-24 h-24 border-baseRed `}
  div {
    ${tw`lg:ml-20 ml-10`}
  }
  white-space: nowrap;
`;
const Container = styled.div`
  ${tw`lg:p-10 mb-12 lg:mb-4`}

  h1 {
    ${tw`font-cinzel font-bold lg:text-4xl  text-sm md:text-base text-gray-800 lg:mb-2`}
  }
  p {
    ${tw`font-cinzel lg:text-2xl text-xs md:text-sm font-medium text-baseRed leading-6`}
  }
  .line1 {
    ${tw``}
    border-right: 4px solid #c41421;
    height: 45px;
    position: relative;
    bottom: 105px;
    right: 390px;
  }
  @media screen and (max-width: 480px) {
    .line1 {
      height: 15px;
      bottom: 40px;
      right: 176px;
    }
  }
  @media screen and (width: 540px) {
    .line1 {
      height: 18px;
      top: -39px;
      left: -175px;
    }
  }
  @media screen and (width: 768px) {
    .line1 {
      height: 18px;
      bottom: 55px;
      right: 175px;
    }
  }
  .line2 {
    border-right: 4px solid #c41421;
    height: 45px;
    position: relative;
    bottom: -105px;
    right: 470px;
  }
  @media screen and (max-width: 480px) {
    .line2 {
      height: 15px;
      top: 40px;
      left: -220px;
    }
  }
  @media screen and (width: 768px) {
    .line2 {
      height: 18px;
      top: 55px;
      left: -218px;
    }
  }
  @media screen and (width: 540px) {
    .line2 {
      height: 18px;
      top: 39px;
      left: -218px;
    }
  }
`;

const Column = styled.div`
  ${tw`w-full mt-20 lg:flex lg:justify-between`}
`;
const ColumnOne = styled.div`
  ${tw`lg:w-1/3 w-2/4 rounded-full m-auto `}
`;
const ColumnTwo = styled.div`
  ${tw`lg:w-2/4 w-full md:w-5/6 md:my-6 md:leading-10 mx-auto shadow-card p-5 rounded-lg leading-6 lg:leading-10 text-gray-800 mt-4 lg:mt--5`}
`;

const ExtraLinksContainer = tw.div`inline-block hover:border-white px-24 hidden md:block `;

function MeetPartners() {
  const navLinks = [
    <ExtraLinksContainer>
      <NavLinks key={1}>
        {/* <NavLink href="#about">
        About
      </NavLink> */}

        <NavLink href='/#'>
          <IconContainer>
            <LocationIcon />
          </IconContainer>
          0706 339 8622
        </NavLink>
        <NavLink href='/#'>
          <IconContainer>
            <EmailIcon />
          </IconContainer>
          info@RKGproperties.com
        </NavLink>
        <SocialLinksContainer>
          {/* <SocialLink href="https://facebook.com">
            <FacebookIcon />
          </SocialLink> */}
          {/* <SocialLink href="https://twitter.com">
            <TwitterIcon />
          </SocialLink> */}
          <SocialLink href='https://www.instagram.com/rkgproperties/?utm_medium=copy_link'>
            <InstagramIcon />
          </SocialLink>
          {/* <SocialLink href="https://twitter.com">
            <LinkednIcon />
          </SocialLink> */}
          {/* <SocialLink href="https://twitter.com">
            <CalendlyIcon />
          </SocialLink> */}
        </SocialLinksContainer>
      </NavLinks>
    </ExtraLinksContainer>,
  ];
  return (
    <>
  
      <AnimationRevealPage>
        <StyledHeader links={navLinks} />
        <Title>OUR SERVICES</Title>
        <Container>
          <Wrapper>
            <div>
              <h1>SALES</h1>
              {/* <p>MANAGING PARTNER/BROKER</p> */}
            </div>
            <div className='line1'></div>
            <div className='line2'></div>
          </Wrapper>
          <Services />
        </Container>

        <Container>
          <Wrapper>
            <div>
              <h1>CONSULTATION</h1>
            </div>
            {/* <div className="line1"></div> */}
            {/* <div className="line2"></div> */}
          </Wrapper>
          <ServicesTwo />
        </Container>

        <Container>
          <Wrapper>
            <div>
              <h1>INVESTMENTS</h1>
            </div>
            {/* <div className="line1"></div> */}
            {/* <div className="line2"></div> */}
          </Wrapper>
          <ServicesThree />
        </Container>

        <Container>
          <Wrapper>
            <div>
              <h1>RENTALS</h1>
            </div>
            {/* <div className="line1"></div> */}
            {/* <div className="line2"></div> */}
          </Wrapper>
          <ServicesFour />
        </Container>
      </AnimationRevealPage>
      <Footer />
    </>
  );
}

export default MeetPartners;
