import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import tw from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import { Link } from 'react-router-dom'
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from '../misc/Headings.js'
import { PrimaryButton } from '../misc/Buttons.js'
import { ReactComponent as QuotesLeftIcon } from '../../images/quotes-l.svg'
import { ReactComponent as QuotesRightIcon } from '../../images/quotes-r.svg'
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg'
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-4.svg'
import { ReactComponent as SvgDecoratorBlob2 } from '../../images/svg-decorator-blob-5.svg'

import 'slick-carousel/slick/slick.css'

const Container = tw.div`relative`
const Content = tw.div`mx-auto py-20 lg:py-20`
const TestimonialsContainer = tw.div`mt-16 lg:mt-0`
const Testimonials = styled.div``
const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`

const TestimonialImageSlider = tw(
  Slider
)`relative w-full flex-shrink-0 flex-wrap `
const TestimonialTextSlider = tw(Slider)``
const TestimonialText = tw.div`outline-none`

const ImageAndControlContainer = tw.div`relative outline-none `
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144 relative`,
])

const ControlContainer = tw.div`absolute bottom-0 right-0  px-6 py-4 rounded-tl-3xl `
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full text-gray-100 p-2 bg-baseRed`}
  svg {
    ${tw`w-5 h-5`}
  }
  button {
    ${tw`outline-none`}
  }
`

const TextContainer = styled.div((props) => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
])

const Subheading = tw(SubheadingBase)`mb-4`

const Title = styled.div`
  ${tw`font-cinzel text-black mb-4 font-bold lg:text-4xl md:text-3xl text-2xl`}
`

const HeadingTitle = tw(
  SectionHeading
)`lg:text-left leading-tight text-gray-lightest font-cinzel`
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`

const QuoteContainer = tw.div`relative mt-10 lg:mt-20`
const Quote = tw.blockquote`text-center lg:text-left text-sm text-white  lg:text-xl xl:text-2xl`
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`
const CustomerProfilePicture = tw.img`rounded-full w-20 h-20`
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0 `
const CustomerName = tw.h5`font-semibold text-xl lg:text-2xl xl:text-3xl text-primary-500 text-yellow-500`
const CustomerTitle = tw.p`font-medium text-secondary-100 text-white`

const DescriptionSection = styled.div`
  ${tw` rounded-lg font-cinzel hidden lg:block md:block lg:w-1/4  p-1 bg-black  bg-opacity-50 text-white items-baseline  absolute bottom-0 left-0 `}
  h1 {
    ${tw`text-white font-bold text-lg xl:text-xl`}
  }

  h2 {
    ${`text-red-500 text-bold`}
  }
`

const QuotesLeft = tw(
  QuotesLeftIcon
)`w-6 h-6 opacity-75 text-yellow-500 inline-block mr-1 -mt-3`
const QuotesRight = tw(
  QuotesRightIcon
)`w-6 h-6 opacity-75 text-yellow-500 inline-block ml-1 -mt-3`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`

export default ({
  subheading = '',
  heading = '',
  description = '',
  testimonials = null,
  textOnLeft = false,
}) => {
  const defaultTestimonials = [
    {
      imageSrc:
        'https://red-keys.s3.us-east-2.amazonaws.com/stratton-gate/stratton-gate4.png',
      profileImageSrc:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',

      customerName: 'Bimbo A.',
      title: 'Stratongate Condos',
      price: '₦ 180,000,000',
      location: 'Kofo Abayomi, VI',
      beds: '2 Beds ',
      baths: '2 Baths',
      link: '/stratongate',
    },
    {
      imageSrc: 'https://i.ibb.co/R0zBj5t/simi-gardens2.jpg',
      profileImageSrc:
        'https://red-keys.s3.us-east-2.amazonaws.com/simi-gardens/hah.png',

      title: 'Simi Gardens',
      price: '₦205,000,000',
      location: 'Lekki Phase I',
      beds: '4 Beds ',
      baths: '4 Baths',
      link: '/simi-gardens',
    },

    {
      imageSrc:
        'https://i.ibb.co/JChwXz1/Screenshot-2021-10-07-at-10-46-48.png',

      profileImageSrc:
        'https://i.ibb.co/JChwXz1/Screenshot-2021-10-07-at-10-46-48.png',

      title: 'The Dynasty',
      price: 'From: ₦ 85,000,000',
      location: 'Orange Island, Lekki',
      beds: '4 Beds ',
      baths: '4 Baths',
      link: '/dynasty',
    },
    {
      imageSrc:
        'https://i.ibb.co/ZWJY2mD/Screenshot-2022-02-16-at-11-53-18.png',

      profileImageSrc:
        'https://i.ibb.co/JChwXz1/Screenshot-2021-10-07-at-10-46-48.png',

      title: 'Palomina Place',
      price: 'From: ₦ 300,000,000',
      location: 'Turnbull Rd, Ikoyi',
      beds: '4 Beds ',
      baths: '4 Baths',
      link: '/palmomina-place',
    },
    // {
    //   imageSrc:
    //     'https://i.ibb.co/JChwXz1/Screenshot-2021-10-07-at-10-46-48.png',

    //   profileImageSrc:
    //     'https://i.ibb.co/JChwXz1/Screenshot-2021-10-07-at-10-46-48.png',

    //   title: 'Mulberry Mansions',
    //   price: 'From: ₦ 85,000,000',
    //   location: 'Onikoyi',
    //   beds: '4 Beds ',
    //   baths: '4 Baths',
    //   link: '/dynasty',
    // },
    // {
    //   imageSrc:
    //     'https://i.ibb.co/JChwXz1/Screenshot-2021-10-07-at-10-46-48.png',

    //   profileImageSrc:
    //     'https://i.ibb.co/JChwXz1/Screenshot-2021-10-07-at-10-46-48.png',

    //   title: 'Lekki Penninsula Land',
    //   price: 'From: ₦ 85,000,000',
    //   location: 'Elegushi',
    //   beds: '4 Beds ',
    //   baths: '4 Baths',
    //   link: '/dynasty',
    // },
  ]

  if (!testimonials || testimonials.length === 0)
    testimonials = defaultTestimonials

  const NextArrow = ({ currentSlide, slideCount, ...props }) => (
    <ControlButton tw='absolute right-5 bottom-5  '>
      <button {...props}>
        <ChevronRightIcon />
      </button>
    </ControlButton>
  )
  const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
    <ControlButton tw='absolute right-20  bottom-5 z-10 '>
      <button {...props}>
        <ChevronLeftIcon />
      </button>
    </ControlButton>
  )

  const Btn = styled.div`
    ${tw` border-2 border-red-600 text-sm md:text-base lg:text-lg w-48 md:w-56 lg:w-64 my-4 lg:px-2 font-bold tracking-wider py-2 mx-auto text-center text-gray-900 cursor-pointer transition duration-300 ease-in hover:bg-red-600 hover:text-white font-cinzel `}
  `
  const PropBtn = styled.div`
    ${tw` border-2 border-red-600 text-sm md:text-base lg:text-lg w-48 md:w-56 xl:w-64 my-4 font-bold tracking-wider py-2  text-center text-white cursor-pointer transition duration-300 ease-in hover:bg-red-600 hover:text-white font-cinzel `}
  `

  const GlobalBtn = () => {
    return (
      <>
        <Link to='/properties'>
          <Btn>View All Properties</Btn>
        </Link>
      </>
    )
  }

  return (
    <Container id='testimonials'>
      <Content>
        <HeadingInfo
          tw='text-center lg:hidden'
          subheading={subheading}
          heading={heading}
          description={description}
        />

        <TestimonialsContainer>
          <Title>Featured Properties</Title>
          <TestimonialImageSlider
            nextArrow={<NextArrow />}
            prevArrow={<PreviousArrow />}
          >
            {testimonials.map((testimonial, index) => (
              <ImageAndControlContainer key={index}>
                <Image imageSrc={testimonial.imageSrc}>
                  <DescriptionSection>
                    <h1>{testimonial.title}</h1>
                    <h1>{testimonial.price}</h1>
                    <h1>{testimonial.location}</h1>
                    <h1>{testimonial.beds}</h1>
                    <h1>{testimonial.baths}</h1>

                    <Link to={testimonial.link}>
                      <PropBtn>View Property</PropBtn>
                    </Link>
                  </DescriptionSection>
                </Image>
              </ImageAndControlContainer>
            ))}
          </TestimonialImageSlider>
        </TestimonialsContainer>
      </Content>

      <GlobalBtn />
    </Container>
  )
}

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? <Subheading>{subheading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
)
