import React, { useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link } from 'react-router-dom'
import { css } from 'styled-components/macro' //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from '../misc/Headings.js'
import { PrimaryButton } from '../misc/Buttons.js'
import { ReactComponent as QuotesLeftIcon } from '../../images/quotes-l.svg'
import { ReactComponent as QuotesRightIcon } from '../../images/quotes-r.svg'
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg'
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-4.svg'
import { ReactComponent as SvgDecoratorBlob2 } from '../../images/svg-decorator-blob-5.svg'
import { ReactComponent as LocationIcon } from 'feather-icons/dist/icons/map-pin.svg'

import 'slick-carousel/slick/slick.css'

const Container = tw.div`relative`
const Content = tw.div` mx-auto lg:py-20 pb-6`
const TestimonialsContainer = tw.div`lg:mt-10`
const Testimonials = styled.div``
const Testimonial = tw.div` lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`

const TestimonialImageSlider = tw(Slider)`w-full lg:w-full flex-shrink-0 `
const TestimonialTextSlider = tw(Slider)``
const TestimonialText = tw.div`outline-none`

const ImageAndControlContainer = tw.div`relative outline-none`
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144`,
])

const ControlContainer = tw.div`absolute bottom-0 bg-transparent px-6 py-4 rounded-tl-3xl left-1/2 translate-x-1/2`
const ControlButton = styled(PrimaryButton)`
  ${tw`rounded-full text-gray-100 bg-gray-100 first:ml-0 mx-4 flex hocus:bg-gray-700 p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`
const PropertyInfoContainer = tw.div`absolute bottom-0 right-0 rounded-tl-3xl text-gray-100 bg-gray-800 p-8 lg:sm:p-8 md:sm:p-4 sm:p-4 h-32 sm:p-4 w-auto`
const PropertyNameText = tw.p`uppercase text-center mb-8 md:min-w-full sm:min-w-full`
const PropertyDetailsContainer = tw.div`text-center mb-8 justify-between flex flex-row`

const NavButtonContainer = tw.div`flex`
const LocationInfoContainer = tw.div`flex flex-row`
const CityText = tw.div`mx-2 flex`

const TextContainer = styled.div((props) => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
])

const Subheading = tw(SubheadingBase)`mb-4`
const HeadingTitle = tw(
  SectionHeading
)`lg:text-left leading-tight text-gray-lightest font-cinzel`
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100 `

const Title = styled.div`
  ${tw`font-cinzel text-black mb-4 font-bold lg:text-4xl md:text-3xl text-2xl`}
`

const Btn = styled.div`
  ${tw` border-2 border-red-600 text-sm md:text-base lg:text-lg w-48 md:w-56 lg:w-64 my-4 lg:px-2 font-bold tracking-wider py-2 mx-auto text-center text-gray-900 cursor-pointer transition duration-300 ease-in hover:bg-red-600 hover:text-white font-cinzel  lg:mb-6`}
`

export default ({
  subheading = '',
  heading = 'Featured Properties',
  description = '',
  testimonials = null,

  textOnLeft = false,
}) => {
  /*
   * You can modify the testimonials shown by modifying the array below or passing in the testimonials prop above
   * You can add or remove objects from the array as you need.
   */
  const defaultTestimonials = [
    {
      imageSrc:
        'https://red-keys.s3.us-east-2.amazonaws.com/stratton-gate/strattonGateHeader.jpg',

      profileImageSrc: '/images/simi-gardens/simi-gardens2.jpg',
      quote:
        'High end building with 4 bedroom and a balcony and parking space yada',
      propertyName: 'Simi Gardens',
      city: 'Lekki',
      bedCount: 2,
      bathCount: 2,
      location: 'Plot 144 Johnny Depp, Oniru',
      propertyPrice: '₦300,000,000',
      link: '/simi-gardens',
    },
    {
      imageSrc: 'https://i.ibb.co/qxPMQbY/hallmark-2.jpg',
      profileImageSrc: 'https://i.ibb.co/qxPMQbY/hallmark-2.jpg',

      quote:
        'High end building with 4 bedroom and a balcony and parking space yada',
      propertyName: 'HallMark',
      city: 'Ikoyi',
      bedCount: 3,
      bathCount: 2,
      location: 'Plot 144 Johnny Depp, Oniru',
      propertyPrice: '₦150,000,000',
      link: '/hallmark',
    },
    {
      imageSrc: 'https://i.ibb.co/rHjXFfp/stratton-gate4.png',

      profileImageSrc:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
      quote:
        'High end building with 4 bedroom and a balcony and parking space yada',
      propertyName: 'Stratton Gate',
      city: 'Ikoyi',
      bedCount: 1,
      bathCount: 1,
      location: 'Plot 144 Johnny Depp, Oniru',
      propertyPrice: '₦150,000,000',
      link: '/stratongate',
    },
  ]

  if (!testimonials || testimonials.length === 0)
    testimonials = defaultTestimonials

  var link = '/simiGardens'

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null)
  const [textSliderRef, setTextSliderRef] = useState(null)

  return (
    <Container>
      <Content>
        <HeadingInfo
          tw='text-center lg:hidden'
          subheading={subheading}
          heading={heading}
          description={description}
        />
        <Title>Featured Properties</Title>

        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider
                arrows={false}
                ref={setImageSliderRef}
                asNavFor={textSliderRef}
                fade={true}
              >
                {testimonials.map((testimonial, index) => (
                  <Link to='/properties'>
                    <ImageAndControlContainer key={index}>
                      <Image imageSrc={testimonial.imageSrc} />

                      <ControlContainer>
                        <NavButtonContainer>
                          {testimonials.map((tstml, clickedIndex) => (
                            <ControlButton
                              key={clickedIndex}
                              onClick={(e) => {
                                link = testimonials[clickedIndex].link
                                e.preventDefault()
                                imageSliderRef.slickGoTo(clickedIndex)
                              }}
                            ></ControlButton>
                          ))}
                        </NavButtonContainer>
                      </ControlContainer>

                      <PropertyInfoContainer>
                        <PropertyNameText>
                          {testimonial.propertyName}
                        </PropertyNameText>
                        <PropertyDetailsContainer>
                          <LocationInfoContainer>
                            <LocationIcon />
                            <CityText>{testimonial.city}</CityText>
                          </LocationInfoContainer>
                          Beds: {testimonial.bedCount} | Bath:{' '}
                          {testimonial.bathCount}
                        </PropertyDetailsContainer>
                      </PropertyInfoContainer>
                    </ImageAndControlContainer>
                  </Link>
                ))}
              </TestimonialImageSlider>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
      <Link to='/properties'>
        <Btn>View Properties</Btn>
      </Link>
    </Container>
  )
}

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? <Subheading>{subheading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
)
