import React from 'react';
import { InlineWidget } from 'react-calendly';

const Calendly = () => {
  return (
    <div className='App'>
      {/* <InlineWidget url="https://calendly.com/flenager-demo/15min"/> */}
      <InlineWidget url='https://calendly.com/chrisap/initial-conversation' />
    </div>
  );
};

export default Calendly;
