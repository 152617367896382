import React, { useEffect } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import { ReactComponent as LocationIcon } from '../images/phone-icon2.svg'
import { ReactComponent as EmailIcon } from '../images/email-icon.svg'
import logo from '../images/rk-intro.gif'
import { ReactComponent as FacebookIcon } from '../images/facebook-icon.svg'
import { ReactComponent as TwitterIcon } from '../images/twitter-icon.svg'
import { ReactComponent as InstagramIcon } from '../images/instagram-icon.svg'
import { ReactComponent as LinkednIcon } from '../images/linkedin-icon.svg'
import { ReactComponent as CalendlyIcon } from '../images/calendly-icon.svg'
import Ike from '../images/Ike Onyekweli.jpg'
import Doyle from '../images/Tsehe Doyle-Egunjobi.jpeg'
import AnimationRevealPage from '../helpers/AnimationRevealPage'
import Footer from 'components/footers/MiniCenteredFooter.js'

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from '../components/headers/light.js'


const SocialLinksContainer = tw.div`ml-12 inline`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block   hover:text-red-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`
const IconContainer = styled.div`
  ${tw`inline-grid rounded-full mr-1  `}
  svg {
    ${tw`w-6 h-3  `}
  }
`
const Title = styled.div`
  ${tw`font-cinzel font-bold text-gray-800 text-2xl md:text-3xl lg:text-5xl text-center lg:my-20 md:my-16 my-12`}
`

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black hover:border-baseRed hover:text-black text-base font-light`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500 `}
  }
`
const Wrapper = styled.div`
  ${tw`border-4 border-r-0 flex items-center lg:w-56 lg:h-64 md:h-32 md:w-32 w-24 h-24 border-baseRed `}
  div {
    ${tw`lg:ml-20 ml-10`}
  }
  white-space: nowrap;
`
const Container = styled.div`
  ${tw`lg:p-10 mb-12`}

  h1 {
    ${tw`font-cinzel font-bold lg:text-4xl  text-sm md:text-base text-gray-800 lg:mb-2`}
  }
  p {
    ${tw`font-cinzel lg:text-2xl text-xs md:text-sm font-medium text-baseRed leading-6`}
  }
  .line1 {
    ${tw``}
    border-right: 4px solid #c41421;
    height: 45px;
    position: relative;
    bottom: 105px;
    right: 390px;
  }
  @media screen and (max-width: 480px) {
    .line1 {
      height: 15px;
      bottom: 40px;
      right: 176px;
    }
  }
  @media screen and (width: 540px) {
    .line1 {
      height: 18px;
      top: -39px;
      left: -175px;
    }
  }
  @media screen and (width: 768px) {
    .line1 {
      height: 18px;
      bottom: 55px;
      right: 175px;
    }
  }
  .line2 {
    border-right: 4px solid #c41421;
    height: 45px;
    position: relative;
    bottom: -105px;
    right: 470px;
  }
  @media screen and (max-width: 480px) {
    .line2 {
      height: 15px;
      top: 40px;
      left: -220px;
    }
  }
  @media screen and (width: 768px) {
    .line2 {
      height: 18px;
      top: 55px;
      left: -218px;
    }
  }
  @media screen and (width: 540px) {
    .line2 {
      height: 18px;
      top: 39px;
      left: -218px;
    }
  }
`
const Column = styled.div`
  ${tw`w-full mt-20 lg:flex lg:justify-between items-center`}
  @media screen and (width: 1024px) {
    .ike {
      height: 60em;
      width: 73em;
      object-fit: cover;
    }
    .doyle {
      height: 55em;
      width: 80em;
      object-fit: cover;
    }
  }
`
const ColumnOne = styled.div`
  ${tw`lg:w-1/3    `}
`
const ColumnTwo = styled.div`
  ${tw`lg:w-2/4 w-full md:w-5/6 md:my-6 md:leading-10 m-auto shadow-card p-5 rounded-lg leading-6 lg:leading-10 text-gray-800 mt-4`}
`

const ExtraLinksContainer = tw.div`inline-block hover:border-white px-24 hidden md:block `
function MeetPartners() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navLinks = [
    <ExtraLinksContainer>
      <NavLinks key={1}>
        {/* <NavLink href="#about">
        About
      </NavLink> */}

        <NavLink href='/#'>
          <IconContainer>
            <LocationIcon />
          </IconContainer>
          0706 339 8622
        </NavLink>
        <NavLink href='/#'>
          <IconContainer>
            <EmailIcon />
          </IconContainer>
          info@RKGproperties.com
        </NavLink>
        <SocialLinksContainer>
          <SocialLink href='https://www.instagram.com/rkgproperties/?utm_medium=copy_link'>
            <InstagramIcon />
          </SocialLink>
        </SocialLinksContainer>
      </NavLinks>
    </ExtraLinksContainer>,
  ]
  return (
    <>
      <AnimationRevealPage>
        <StyledHeader links={navLinks} />
        <Title>MEET THE PARTNERS</Title>
        <Container>
          <Wrapper>
            <div>
              <h1>Tseshe Doyle-Egunjobi</h1>
              <p>MANAGING PARTNER/BROKER</p>
            </div>
          </Wrapper>
          <Column>
            <ColumnOne>
              <img className='doyle' src={Doyle} alt='loading...' />
            </ColumnOne>
            <ColumnTwo>
              Tseshe brings with her over a decade’s experience in the Nigerian
              Real Estate Market. After working in Corporate America for several
              years, she was presented with the opportunity to service the Real
              Estate needs of several corporate clients here in Lagos. And after
              gaining much success in that field, she soon branched out to make
              her mark in the Property Market space by Starting her own
              Brokerage (AAE Global Properties), that specialized in Property
              Investment, Rentals and Acquisitions to managing Major
              Corporate/Commercial Clients Portfolio with over N5billion in
              Sales/Rentals. <br /> <br /> As Managing Partner, Tseshe leads by
              example by ensuring the Team not only adheres to Real Estate Best
              Practices, but always ensures our Value System of Integrity,
              Quality, Professionalism, Transparency and a Little Fun are at the
              forefront of every Client Request.
            </ColumnTwo>
          </Column>
        </Container>

        <Container>
          <Wrapper>
            <div>
              <h1>Ike Onyekweli</h1>
              <p>MANAGING PARTNER/BROKER</p>
            </div>
          </Wrapper>
          <Column>
            <ColumnOne>
              <img className='ike' src={Ike} alt='loading...' />
            </ColumnOne>
            <ColumnTwo>
              Ike comes with a wealth of real estate experience that precedes
              his move to Nigeria over 10 years ago. Starting off in 2008
              running a small brokerage (Trace Atlantic Limited and Caerus
              Strategic Development Ltd in 2012) that serviced real estate needs
              for Total, BAT, Exxon Mobil, Schneider Electric, Visionscape and
              many more reputable companies in the corporate and commercial
              rental markets. <br />
              <br />
              With a real Passion for the Property Market, Teaming up with
              Tseshe to form RKG Properties was the best collaboration to ensure
              we secure our Space to grow into a Leading Real Estate Brokerage
              in Nigeria. <br /> <br />
              As Chief Operating Officer, Ike leads by example by ensuring the
              Team works in unison and adheres to the culture of RKG Properties
              while always guaranteeing that RKG operations works at its highest
              capacity to always deliver a client experience to remember. 4
            </ColumnTwo>
          </Column>
        </Container>
      </AnimationRevealPage>
      <Footer />
    </>
  )
}

export default MeetPartners
