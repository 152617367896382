import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import Hero from 'components/hero/TwoColumnWithVideo.js'
import Features from 'components/features/ThreeColSimple.js'
import MainFeature from 'components/features/TwoColSingleFeatureWithStats.js'
import SliderCard from 'components/cards/ThreeColSlider.js'
import TrendingCard from 'components/cards/TwoTrendingPreviewCardsWithImage.js'
import MeetThePartners from 'components/cards/ProfileThreeColGrid'
import Grid from 'components/blogs/GridWithFeaturedPost.js'
// import FeaturedProperty from 'components/testimonials/TwoColumnWithImageAndProfilePictureReview.js'
import FeaturedProperty from 'components/testimonials/FeaturedPropertiesSlider.js'

import FAQ from 'components/faqs/SimpleWithSideImage.js'
import SubscribeNewsLetterForm from 'components/forms/SimpleSubscribeNewsletter.js'
import NavLinks from 'components/hero/TwoColumnWithVideo'
import Footer from 'components/footers/MiniCenteredFooter.js'
import { components } from 'ComponentRenderer.js'
import MeetTheTeam from '../components/team'
import MeetPartners from '../pages/MeetPartners'
import Properties from '../pages/Properties.js'
import ServicePage from '../pages/ServicePage'
import Contact from '../components/forms/ContactUs.js'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import SimiGardens from '../pages/SimiGardens.js'
import StrattonGate from '../pages/StrattonGate'
import Dynasty from '../pages/Dynasty'
import HallMark from '../pages/HallMark'
import LekkiPeninsula from '../pages/LekkiPeninsula'
import Mulberry from '../pages/Mulberry'
import Palomina from '../pages/Palomina'

import Lifestyle from '../pages/Lifestyle'
import Rentals from '../pages/Rentals'
import Sales from '../pages/Sales'
import Consultation from '../pages/Consultation'

export default () => {
  return (
    <Router>
      <Route exact path='/'>
        <AnimationRevealPage>
          <Hero />
          <FeaturedProperty />
          {/* <SliderCard /> */}
          {/* <TrendingCard /> */}
          <Grid />
          {/* <FAQ /> */}
          {/* <SubscribeNewsLetterForm /> */}
          <MeetTheTeam />
        </AnimationRevealPage>
        <Footer />
      </Route>

      <Route path='/contact'>
        <Contact />
      </Route>
      <Route path='/about'>
        <Features />
      </Route>
      <Route path='/properties'>
        <Properties />
      </Route>
      <Route path='/meet-the-partner'>
        <MeetPartners />
      </Route>
      <Route path='/simi-gardens'>
        <SimiGardens />
      </Route>
      <Route path='/stratongate'>
        <StrattonGate />
      </Route>
      {/* <Route path='/hallmark'>
        <HallMark />
      </Route> */}
      <Route path='/dynasty'>
        <Dynasty />
      </Route>
      <Route path='/palmomina-place'>
        <Palomina />
      </Route>
      <Route path='/mulberry-mansions'>
        <Mulberry />
      </Route>
      <Route path='/lekki-penninsula-land'>
        <LekkiPeninsula />
      </Route>
      <Route path='/services'>
        <ServicePage />
      </Route>
      <Route path='/lifestyle'>
        <Lifestyle />
      </Route>
      <Route path='/rentals'>
        <Rentals />
      </Route>
      <Route path='/sales'>
        <Sales />
      </Route>
      <Route path='/consultation'>
        <Consultation />
      </Route>
    </Router>
  )
}
