import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link } from 'react-router-dom'
import { css } from 'styled-components/macro' //eslint-disable-line
// import { ReactComponent as SvgDotPatternIcon } from '../../../images/dot-pattern.svg';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import Hero from 'components/hero/TwoColumnWithVideo.js'
import axios from 'axios'
import { ReactComponent as LocationIcon } from '../../images/phone-icon2.svg'
import { Modal } from 'react-responsive-modal'
import { ReactComponent as EmailIcon } from '../../images/email-icon.svg'

import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg'
import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg'
import { ReactComponent as InstagramIcon } from '../../images/instagram-icon.svg'
import { ReactComponent as LinkednIcon } from '../../images/linkedin-icon.svg'
import { ReactComponent as CalendlyIcon } from '../../images/linkedin-icon.svg'
import Footer from 'components/footers/MiniCenteredFooter.js'
import emailjs from 'emailjs-com'

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from '../headers/light.js'



const IconContainer = styled.div`
  ${tw`inline-grid rounded-full mr-1  `}
  svg {
    ${tw`w-6 h-3  `}
  }
`

const SocialLinksContainer = tw.div`ml-12 inline`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block   hover:text-red-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`

const Container = tw.div`relative `
const Content = tw.div` lg:w-3/6 sm:w-5/6  mx-auto py-20 lg:py-24 border-4 border-red-600 rounded-tl-2xl rounded-br-2xl `

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`
//Source Code for Form: https://tailwindcomponents.com/component/basic-contact-form

const HeaderTitle = styled.div`
  ${tw`font-cinzel font-bold text-gray-800 text-2xl md:text-3xl lg:text-5xl text-center my-6`}
`
const HeaderSubtitle = styled.div`
  ${tw`font-cinzel text-2xl   text-center my-5`}
`
const Form = tw.form`w-full  mx-auto
`
const OuterDiv = tw.div`mx-12 mb-6   `
const Title = tw.div`w-full  px-3 mb-6 md:mb-0`
const TitleLabel = tw.div` font-cinzel font-bold block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`
const Input = tw.input` w-full appearance-none block bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`

const Message = tw.div`flex flex-wrap -mx-3 mb-6 px-3`
const MessageInner = tw.div`w-full px-3`
const MessageLabel = tw.label` font-cinzel font-bold block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 `
const MessageArea = tw.textarea`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize `

const SendButton = tw.div`md:flex md:items-center`

const Button = tw.button` font-cinzel font-bold shadow border-2 border-red-500  focus:shadow-outline focus:outline-none text-black font-bold py-2 ease-in hover:bg-red-600 hover:text-white px-4 rounded mx-auto `
const ModalButton = tw.button` font-cinzel font-bold shadow border-2 border-red-500  focus:shadow-outline focus:outline-none text-black font-bold py-2 ease-in hover:bg-red-600 hover:text-white px-4 rounded items-center`

const Alert = tw.div`m-auto font-bold text-gray-700 text-center font-cinzel tracking-widest italic mb-2`

const ExtraLinksContainer = tw.div`inline-block hover:border-white px-24 hidden md:block `

const ModalContainer = styled.div`
  ${tw` flex flex-wrap lg:p-4 m-10  justify-center items-center`}
`
// const ModalContent = tw.div`  items-center border-2 border-red-500`

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black hover:border-baseRed hover:text-black text-base font-light`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500 `}
  }
`

const Wrapper = styled.div`
  ${tw`border-4 border-r-0 flex justify-center items-center  lg:w-3/12 lg:h-6/12 md:h-24 md:w-24 w-16 h-12 border-baseRed py-10`}
  h1 {
    ${tw`font-cinzel  font-bold lg:text-3xl  text-sm md:text-base text-gray-800 lg:mb-2`}
    position: relative;
    top: 5px;
    left: 65px;
  }

  white-space: nowrap;
`

export default () => {
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  function refreshPage() {
    window.location.reload(false)
  }
  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_d3sqabj',
        'template_r4x0vgn',
        e.target,
        'user_3upRs0j9qJy7MUL32vi62'
      )
      .then(
        (result) => {
          console.log(result.text)
          console.log('Success!')
          onOpenModal()
          // refreshPage()
        },
        (error) => {
          console.log('Failure!')
          console.log(error.text)
        },
        e.target.reset
      )
  }

  const navLinks = [
    <ExtraLinksContainer>
      <NavLinks key={1}>
        {/* <NavLink href="#about">
        About
      </NavLink> */}

        <NavLink href='/#'>
          <IconContainer>
            <LocationIcon />
          </IconContainer>
          0706 339 8622
        </NavLink>
        <NavLink href='/#'>
          <IconContainer>
            <EmailIcon />
          </IconContainer>
          info@RKGproperties.com
        </NavLink>
        <SocialLinksContainer>
          {/* <SocialLink href='https://facebook.com'>
            <FacebookIcon />
          </SocialLink>
          <SocialLink href='https://twitter.com'>
            <TwitterIcon />
          </SocialLink> */}
          <SocialLink href='https://www.instagram.com/rkgproperties/'>
            <InstagramIcon />
          </SocialLink>
          {/* <SocialLink href='https://twitter.com'>
            <LinkednIcon />
          </SocialLink>
          <SocialLink href='https://twitter.com'>
            <CalendlyIcon />
          </SocialLink> */}
        </SocialLinksContainer>
      </NavLinks>
    </ExtraLinksContainer>,
  ]
  return (
    <Container>
      <StyledHeader links={navLinks} />
      <HeaderTitle>Contact Us</HeaderTitle>

      <Content>
        <HeaderSubtitle>
          Interested to Find out more about our Services?
        </HeaderSubtitle>
        <Form onSubmit={sendEmail}>
          <OuterDiv>
            <Title>
              <TitleLabel>Name</TitleLabel>
              <Input
                id='grid-first-name'
                type='text'
                placeholder='James Bond'
                name='from_name'
              ></Input>
            </Title>
            <Title>
              <TitleLabel>Email</TitleLabel>
              <Input
                id='email'
                type='text'
                required
                placeholder='jamesbond@gmail.com'
                name='from_email'
              ></Input>
            </Title>
            <Title>
              <TitleLabel>Phone Number</TitleLabel>
              <Input
                id='phoneNumber'
                type='text'
                placeholder='07081234567'
                name='from_number'
              ></Input>
            </Title>
            <Message>
              <MessageInner>
                <MessageLabel>Message</MessageLabel>
                <MessageArea id='message' name='message'></MessageArea>
              </MessageInner>
            </Message>
            {/* Come back and fix this message alert */}
            {/* <Alert>Message has been sent</Alert> */}
            <SendButton>
              <Button type='submit' value='Send'>
                Send
              </Button>
            </SendButton>
          </OuterDiv>
        </Form>
        {/* <Button onClick={onOpenModal}>Request Info</Button> */}
        <Modal open={open} onClose={(onCloseModal, refreshPage)} center>
          <ModalContainer>
            {/* <ModalContent> */}
            Your message has been sent
          </ModalContainer>
          <Link to='/'></Link>
        </Modal>
      </Content>
      <Footer />
    </Container>
  )
}
