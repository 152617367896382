import React, { useEffect } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import { ReactComponent as LocationIcon } from '../images/phone-icon2.svg'
import { ReactComponent as EmailIcon } from '../images/email-icon.svg'
import logo from '../images/rk-intro.gif'
import { ReactComponent as FacebookIcon } from '../images/facebook-icon.svg'
import { ReactComponent as TwitterIcon } from '../images/twitter-icon.svg'
import { ReactComponent as InstagramIcon } from '../images/instagram-icon.svg'
import { ReactComponent as LinkednIcon } from '../images/linkedin-icon.svg'
import { ReactComponent as CalendlyIcon } from '../images/calendly-icon.svg'
import Ike from '../images/Ike Onyekweli.jpg'
import Doyle from '../images/Tsehe Doyle-Egunjobi.jpeg'
import AnimationRevealPage from '../helpers/AnimationRevealPage'
import Footer from 'components/footers/MiniCenteredFooter.js'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from '../components/headers/light.js'

const SocialLinksContainer = tw.div`ml-12 inline`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block   hover:text-red-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`
const IconContainer = styled.div`
  ${tw`inline-grid rounded-full mr-1  `}
  svg {
    ${tw`w-6 h-3  `}
  }
`
const Title = styled.div`
  ${tw`font-cinzel font-bold text-gray-800 text-2xl md:text-3xl lg:text-5xl text-center mt-16`}
`

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black hover:border-baseRed hover:text-black text-base font-light`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500 `}
  }
`
const Wrapper = styled.div`
  ${tw`border-4 border-r-0 flex items-center lg:w-56 lg:h-64 md:h-32 md:w-32 w-24 h-24 border-baseRed `}
  div {
    ${tw`lg:ml-20 ml-10`}
  }
  white-space: nowrap;
`

const Container = tw.div`relative`
const Content = tw.div` mx-auto py-2 lg:py-8`
const TestimonialsContainer = tw.div`mt-16 lg:mt-16`
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144`,
])

const LinkBtn = tw.span`border-b-2 border-transparent  pb-1 pt-10 transition duration-300  items-center justify-center w-full -mt-4  flex`
const PrimaryAction = tw.button` px-8 py-3 text-sm sm:text-base  sm:px-8 sm:py-4 bg-transparent font-medium shadow transition duration-300 border border-baseRed text-black font-cinzel tracking-widest hocus:text-gray-200  ease-in hover:bg-red-600 hover:text-white focus:outline-none focus:shadow-outline`

const Column = styled.div`
  ${tw`w-full mt-20 lg:flex lg:justify-between items-center`}
`
const ColumnOne = styled.div`
  ${tw`lg:w-1/3 w-2/4 rounded-full m-auto `}
`
const ColumnTwo = styled.div`
  ${tw`lg:w-2/4 w-full md:w-5/6 md:my-6 md:leading-10 m-auto shadow-card p-5 rounded-lg leading-6 lg:leading-10 text-gray-800 mt-4`}
`

const ExtraLinksContainer = tw.div`inline-block hover:border-white px-24 hidden md:block `

function Properties() {
  const ClickHandler = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click the property link',
    })
    // alert('Send the information to google analystic that I touched the click button')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navLinks = [
    <ExtraLinksContainer key={1}>
      <NavLinks>
        {/* <NavLink href="#about">
        About
      </NavLink> */}

        <NavLink href='/#'>
          <IconContainer>
            <LocationIcon />
          </IconContainer>
          0706 339 8622
        </NavLink>
        <NavLink href='/#'>
          <IconContainer>
            <EmailIcon />
          </IconContainer>
          info@RKGproperties.com
        </NavLink>
        <SocialLinksContainer>
          {/* <SocialLink href='https://facebook.com'>
            <FacebookIcon />
          </SocialLink>
          <SocialLink href='https://twitter.com'>
            <TwitterIcon />
          </SocialLink> */}
          <SocialLink href='https://twitter.com'>
            <InstagramIcon />
          </SocialLink>
          {/* <SocialLink href='https://twitter.com'>
            <LinkednIcon />
          </SocialLink> */}
          {/* <SocialLink href='https://twitter.com'>
            <CalendlyIcon />
          </SocialLink> */}
        </SocialLinksContainer>
      </NavLinks>
    </ExtraLinksContainer>,
  ]
  return (
    <>
      {/* {GA.logPageView()} */}
      <AnimationRevealPage>
        <StyledHeader links={navLinks} />
        <Title>Featured Properties</Title>

        <Container>
          <Content>
            <TestimonialsContainer>
              {' '}
              <Image imageSrc='https://red-keys.s3.us-east-2.amazonaws.com/simi-gardens/hah.png' />
            </TestimonialsContainer>
            <Title>Simi Gardens</Title>
            <Link to='/simi-gardens'>
              <LinkBtn onClick={ClickHandler}>
                <PrimaryAction>Details</PrimaryAction>
              </LinkBtn>
            </Link>
          </Content>
        </Container>
        <Container>
          <Content>
            <TestimonialsContainer>
              {' '}
              <Image imageSrc='https://i.ibb.co/ZWJY2mD/Screenshot-2022-02-16-at-11-53-18.png' />
            </TestimonialsContainer>
            <Title>Palomina Place</Title>
            <Link to='/palmomina-place'>
              <LinkBtn onClick={ClickHandler}>
                <PrimaryAction>Details</PrimaryAction>
              </LinkBtn>
            </Link>
          </Content>
        </Container>
        <Container>
          <Content>
            <TestimonialsContainer>
              {' '}
              <Image imageSrc='https://red-keys.s3.us-east-2.amazonaws.com/stratton-gate/strattonGateHeader.jpg' />
            </TestimonialsContainer>
            <Title>Stratongate Condos</Title>
            <Link to='/stratongate'>
              <LinkBtn onClick={ClickHandler}>
                <PrimaryAction>Details</PrimaryAction>
              </LinkBtn>
            </Link>
          </Content>
        </Container>
        <Container>
          <Content>
            <TestimonialsContainer>
              {' '}
              <Image imageSrc='https://i.ibb.co/JChwXz1/Screenshot-2021-10-07-at-10-46-48.png' />
            </TestimonialsContainer>
            <Title>The Dynasty</Title>
            <Link to='/dynasty'>
              <LinkBtn>
                <PrimaryAction>Details</PrimaryAction>
              </LinkBtn>
            </Link>
          </Content>
        </Container>
      </AnimationRevealPage>
      <Footer />
    </>
  )
}

export default Properties
