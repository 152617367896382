import React from 'react'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'

import { css } from 'styled-components/macro'
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings'
import { SectionDescription } from 'components/misc/Typography'
import { ReactComponent as SvgDotPatternIcon } from 'images/dot-pattern.svg'

import Image1 from 'images/services/4.svg'

const HeadingContainer = tw.div`text-center`
const Subheading = tw(SubheadingBase)`mb-4`
const Heading = tw(SectionHeading)``
const Description = tw(SectionDescription)`mx-auto`

const PostImage = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw` h-64 bg-center bg-cover `}
`
const PostText = tw.div`pl-8 pr-5 lg:w-1/2 md:w-1/2`
const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `
const PostDescription = tw.p``
const AuthorInfo = tw.div`flex`
const AuthorImage = tw.img`w-12 h-12 rounded-full mr-3`
const AuthorTextInfo = tw.div`text-xs text-gray-600`
const AuthorName = tw.div`font-semibold mt-2`
const AuthorProfile = tw.div`pt-1 font-medium`

const PostContainer = styled.div`
  ${tw`mt-4 sm:w-1/2 lg:w-full bg-white border shadow rounded-tl-5xl rounded-br-5xl lg:flex md:flex items-center`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full sm:w-full `}

      ${PostImage} {
        ${tw`lg:h-80 h-56 w-full lg:w-1/2 md:w-1/2`}
      }

      ${PostTitle} {
        ${tw`text-2xl`}
      }
      ${PostDescription} {
        ${tw`my-4 font-normal text-black text-base lg:text-2xl leading-relaxed `}
      }
      ${AuthorInfo} {
        ${tw`mt-8 flex items-center`}
      }
      ${AuthorName} {
        ${tw`mt-0 font-bold text-gray-700 text-sm`}
      }
    `}
`

const DecoratorBlob1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`
const DecoratorBlob2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`

export default ({
  subheading = '',
  heading = 'We love writing.',
  description = '',
  posts = [
    {
      postImageSrc: 'https://red-keys.s3.us-east-2.amazonaws.com/simi-gardens/updated-image/WhatsApp+Image+2021-08-01+at+20.37.31.jpeg',
      authorImageSrc:
        'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.95&w=512&h=512&q=80',
      title: 'Enjoying the beach life while on a vacation',
      description:
        'At RKG Properties we prioritize the process. We get to know our clients by introducing them to our rental procedures and then the magic starts to happen.',
      authorName: 'Adam Cuppy',
      authorProfile: 'Vlogger',
      url: 'https://reddit.com',
      featured: true,
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {posts.map((post, index) => (
          <PostContainer featured={post.featured} key={index}>
            <PostText>
              {post.featured && (
                <PostDescription>{post.description}</PostDescription>
              )}
            </PostText>
            <PostImage imageSrc={post.postImageSrc} />
          </PostContainer>
        ))}
      </ContentWithPaddingXl>
    </Container>
  )
}
