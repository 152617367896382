import React from 'react'
import tw from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
// import Hero from "components/hero/TwoColumnWithInput.js";
import styled from 'styled-components'
import Hero from 'components/hero/FullWidthWithImage.js'
import Features from 'components/features/ThreeColWithSideImage.js'
import MainFeature from 'components/features/TwoColWithButton.js'
import MainFeature2 from 'components/features/TwoColWithTwoHorizontalFeaturesAndButton.js'
import FeatureWithSteps from 'components/features/TwoColWithSteps.js'
import Pricing from 'components/pricing/ThreePlans.js'
import Testimonial from 'components/testimonials/TwoColumnWithImageAndRating.js'
import FAQ from 'components/faqs/SingleCol.js'
import GetStarted from 'components/cta/GetStarted'
import Footer from 'components/footers/MiniCenteredFooter.js'
import heroScreenshotImageSrc from 'images/hero-screenshot-1.png'
import macHeroScreenshotImageSrc from 'images/hero-screenshot-2.png'
import prototypeIllustrationImageSrc from 'images/prototype-illustration.svg'
import PartnerImageArea from '../components/cards/ProfileThreeColGrid'
import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/briefcase.svg'
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/dollar-sign.svg'
import { motion } from 'framer-motion'
import { SectionHeading } from 'components/misc/Headings.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js'
import Buyers from '../images/meet-the-partners/options/buyers.png'
import Rentals from '../images/meet-the-partners/options/rental.png'
import Sellers from '../images/meet-the-partners/options/sellers.png'
const Row = tw.div`flex flex-col lg:flex-row -mb-10`
const Heading = tw(
  SectionHeading
)`text-left text-gray-lightest lg:text-4xl xl:text-5xl mb-16`
const PostsContainer = tw.div`mt-12 flex flex-col sm:flex-row sm:justify-between lg:justify-start`
const Post = tw(
  motion.a
)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`
const Image = styled(motion.div)((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`,
])
const Title = tw.h5`mt-6 text-xl font-bold transition duration-300 group-hover:text-primary-500`
const AuthorName = tw.h6`font-semibold text-lg`

const RecentPostsContainer = styled.div`
  ${tw`mt-24 lg:mt-0 lg:w-1/3 ml-auto`}
  ${PostsContainer} {
    ${tw`flex flex-wrap lg:flex-col`}
  }
  ${Post} {
    ${tw`flex justify-between mb-10 max-w-none w-full sm:w-1/2 lg:w-auto sm:odd:pr-12 lg:odd:pr-0 mr-0`}
  }
  ${Title} {
    ${tw`text-base xl:text-lg mt-0 mr-4 lg:max-w-xs`}
  }
  ${AuthorName} {
    ${tw`mt-3 text-sm text-secondary-100 font-normal leading-none`}
  }
  ${Image} {
    ${tw`h-56 w-72 flex-shrink-0`}
  }
`
// const PartnerContainer =
// ${tw`mt-24 lg:mt-0 lg:w-1/3`}

const PartnerContainer = styled.div`
  ${tw.div`mt-8 flex-col sm:w-full lg:w-144`}
`

const PostTextContainer = tw.div``

const recentPosts = [
  {
    postImageSrc: Buyers,
    url: 'https://reddit.com',
  },
  {
    postImageSrc: Sellers,
  },
  {
    postImageSrc: Rentals,
  },
]
export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`
  const HighlightedText = tw.span`text-primary-500`

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <ContentWithPaddingXl>
        <Row>
          <PartnerImageArea />

          <RecentPostsContainer>
            <PostsContainer>
              {recentPosts.map((post, index) => (
                <Post key={index} href={post.url} className="group">
                  <PostTextContainer>
                    <Title>{post.title}</Title>
                    <AuthorName>{post.authorName}</AuthorName>
                  </PostTextContainer>
                  <Image imageSrc={post.postImageSrc} />
                </Post>
              ))}
            </PostsContainer>
          </RecentPostsContainer>
        </Row>
      </ContentWithPaddingXl>
      <Footer />
    </AnimationRevealPage>
  )
}
